import React from 'react'

const Supportgroup = () => {
  return (
    <div>

      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSedh-eJxljqkBMEoKLyn8WdKzz9VVC1Ear_sty2XEq2TZaoHQ/viewform?embedded=true" width="1000" height="1977" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  )
}

export default Supportgroup
